module.exports = {
  defaultTitle: 'Hungry Newport',
  logo: 'https://hungrynewport.com/favicon/favicon-512.png',
  author: 'Jake Hassel',
  url: 'https://hungrynewport.com',
  legalName: 'Jake Hassel',
  defaultDescription: 'Lunch time in Newport, RI',
  googleAnalyticsID: 'UA-158344083-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  foundingDate: '2020',
  recaptcha_key: '6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN',
};
